@import url(https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  min-height: 100vh;
  background: #fff;
  font-family: 'Barlow Semi Condensed', sans-serif;
}
.title_defualt{
  font-size: 3rem;
  width: 100%;
  text-align: left;
  margin-top: 50px;
}

a{
  text-decoration: none;
}
.banner_wrapper {
  min-height: 500px;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 5px solid #f50;
  padding: 50px 0;
  background: linear-gradient(to right, rgba(0, 102, 255, 0.274), rgba(255, 51, 0, 0.473)), url(/static/media/bg.755b29df.png);
  background-size: cover;
  background-position: bottom;
}

.banner_wrapper .banner_container {
  min-height: 500px;
  height: auto;
  width: 1366px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
}

.banner_wrapper .banner_container .content_head {
  width: 70%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.banner_wrapper .banner_container .content_head .headline {
  font-size: 4rem;
}

@media (min-width: 0px) and (max-width: 992px) {
  .banner_wrapper .banner_container {
    width: 90%;
  }
  .banner_wrapper .banner_container .content_head {
    width: 80%;
  }
  .banner_wrapper .banner_container .content_head .headline {
    font-size: 2.6rem;
  }
}
/*# sourceMappingURL=style.css.map */
.content_wrapper {
  min-height: 500px;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid #ddd;
  background: #f8f8f8;
}

.content_wrapper .content_container {
  min-height: 300px;
  height: auto;
  width: 1366px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.content_wrapper .content_container .title_defualt {
  width: 70%;
  position: relative;
  text-align: center;
  margin-bottom: 50px;
  display: flex;
  align-self: center;
  margin: 0 auto;
}

.content_wrapper .content_container .title_defualt span {
  height: 60px;
  width: 40px;
  background: rgba(255, 85, 0, 0.411);
  display: block;
  position: absolute;
  top: -20%;
  left: 50px;
}

.content_wrapper .content_container .content_boxes {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 70%;
  margin-top: 80px;
}

.content_wrapper .content_container .content_boxes .box {
  width: 350px;
  margin-bottom: 70px;
  text-align: justify;
}

.content_wrapper .content_container .content_boxes .box .title {
  font-size: 2rem;
  margin-bottom: 30px;
  text-align: left;
  white-space: nowrap;
}

.content_wrapper .content_container .content_boxes .box .title i {
  color: #f50;
}

.content_wrapper .b {
  padding: 50px 0 0 0px;
  background: #fff;
}

.content_wrapper .cta_wrapper {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}

.content_wrapper .cta_wrapper button {
  height: 60px;
  padding: 0 30px;
  background: #f50;
  color: #fff;
  border: none;
}

@media (min-width: 0px) and (max-width: 992px) {
  .content_wrapper {
    min-height: 500px;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid #ddd;
  }
  .content_wrapper .b {
    border-top: 1px solid #ddd;
    padding: 0px;
    padding-top: 100px;
  }
  .content_wrapper .content_container {
    width: 90%;
  }
  .content_wrapper .content_container .title_defualt {
    width: 100%;
    position: relative;
    font-size: 2.6rem;
  }
  .content_wrapper .content_container .title_defualt span {
    height: 60px;
    width: 40px;
    background: rgba(255, 85, 0, 0.411);
    display: block;
    position: absolute;
    top: -10%;
    left: 0px;
  }
  .content_wrapper .content_container .content_boxes {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 70%;
    margin-top: 100px;
  }
  .content_wrapper .content_container .content_boxes .box {
    width: 100%;
    margin-bottom: 100px;
  }
  .content_wrapper .content_container .content_boxes .box .title {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  .content_wrapper .content_container .content_boxes .box .title i {
    color: #f50;
  }
  .content_wrapper .cta_wrapper {
    height: 100px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    margin: 0 auto;
  }
  .content_wrapper .cta_wrapper button {
    height: 60px;
    width: 90%;
    padding: 0 30px;
    background: #f50;
    color: #fff;
    border: none;
  }
}

@media (min-width: 992px) and (max-width: 1250px) {
  .content_wrapper {
    min-height: 500px;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid #ddd;
  }
  .content_wrapper .b {
    border-top: 1px solid #ddd;
    padding: 0px;
  }
  .content_wrapper .content_container {
    width: 90%;
  }
  .content_wrapper .content_container .title_defualt {
    width: 70%;
    position: relative;
    font-size: 2.6rem;
  }
  .content_wrapper .content_container .title_defualt span {
    height: 60px;
    width: 40px;
    background: rgba(255, 85, 0, 0.411);
    display: block;
    position: absolute;
    top: -30%;
    left: 20px;
  }
  .content_wrapper .content_container .content_boxes {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 70%;
    margin-top: 100px;
  }
  .content_wrapper .content_container .content_boxes .box {
    width: 350px;
    margin-bottom: 100px;
    text-align: left;
  }
  .content_wrapper .content_container .content_boxes .box .title {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  .content_wrapper .content_container .content_boxes .box .title i {
    color: #f50;
  }
  .content_wrapper .cta_wrapper {
    height: 100px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }
  .content_wrapper .cta_wrapper button {
    height: 60px;
    width: 50%;
    padding: 0 30px;
    background: #f50;
    color: #fff;
    border: none;
  }
}
/*# sourceMappingURL=style.css.map */
.footer_wrapper {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top: 5px solid #f50;
}

.footer_wrapper .footer_container {
  min-height: 100px;
  height: auto;
  width: calc(1366px - 20%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 0px) and (max-width: 992px) {
  .footer_wrapper .footer_container {
    min-height: 100px;
    height: auto;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer_wrapper .footer_container .right {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1250px) {
  .footer_wrapper .footer_container {
    min-height: 100px;
    height: auto;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer_wrapper .footer_container .right {
    display: none;
  }
}
/*# sourceMappingURL=style.css.map */
.menu_wrapper {
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  transition: 0.3s;
}

.menu_wrapper .logo {
  width: 20%;
}

.menu_wrapper .nav {
  width: 60%;
}

.menu_wrapper .contato {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu_wrapper .contato .circle_icon {
  height: 40px;
  width: 40px;
  background: #f50;
  border-radius: 50px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu_wrapper .contato .circle_icon i {
  color: #fff;
}

.menu_wrapper .contato .tel {
  text-align: left;
  font-size: 13px;
  font-weight: bold;
  padding: 14px 30px;
  text-transform: uppercase;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid #fff !important;
  color: #fff !important;
  font-weight: 400;
  border: 1px solid #f50;
  color: #f50;
}

.menu_wrapper .contato .tel:hover {
  background: #fff !important;
  color: #f50 !important;
}

.menu_wrapper .contato .scrollTel {
  text-align: left;
  font-size: 13px;
  font-weight: bold;
  padding: 14px 30px;
  text-transform: uppercase;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid #f50 !important;
  color: #f50 !important;
  font-weight: 400;
}

.menu_wrapper .contato .scrollTel:hover {
  background: #f50 !important;
  color: #fff !important;
}

.back {
  background: #fff !important;
  box-shadow: 0px 5px 15px 0px #2223252d;
  transition: 0.3s;
  height: 80px;
}
/*# sourceMappingURL=style.css.map */
/* Extra large devices (large laptops and desktops, 1200px and up) */
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .menu_wrapper {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    background: rgba(255, 255, 255, 0);
  }
  .menu_wrapper .logo {
    width: 40%;
  }
  .menu_wrapper .nav {
    width: 60%;
    display: none;
  }
  .menu_wrapper .contato {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_wrapper .contato .circle_icon {
    height: 40px;
    width: 40px;
    background: #f50;
    border-radius: 50px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_wrapper .contato .circle_icon i {
    color: #fff;
  }
  .menu_wrapper .contato .tel {
    text-align: left;
    font-size: 13px;
    font-weight: bold;
  }
  .menu_wrapper .contato .tel .text {
    font-size: 10px;
    color: #f50;
    text-transform: uppercase;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .menu_wrapper {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    background: rgba(255, 255, 255, 0);
  }
  .menu_wrapper .logo {
    width: 40%;
  }
  .menu_wrapper .nav {
    width: 60%;
    display: none;
  }
  .menu_wrapper .contato {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_wrapper .contato .circle_icon {
    height: 40px;
    width: 40px;
    background: #f50;
    border-radius: 50px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_wrapper .contato .circle_icon i {
    color: #fff;
  }
  .menu_wrapper .contato .tel {
    text-align: left;
    font-size: 13px;
    font-weight: bold;
  }
  .menu_wrapper .contato .tel .text {
    font-size: 10px;
    color: #f50;
    text-transform: uppercase;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .menu_wrapper {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    background: rgba(255, 255, 255, 0);
  }
  .menu_wrapper .logo {
    width: 40%;
  }
  .menu_wrapper .nav {
    width: 60%;
    display: none;
  }
  .menu_wrapper .contato {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_wrapper .contato .circle_icon {
    height: 40px;
    width: 40px;
    background: #f50;
    border-radius: 50px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_wrapper .contato .circle_icon i {
    color: #fff;
  }
  .menu_wrapper .contato .tel {
    text-align: left;
    font-size: 13px;
    font-weight: bold;
  }
  .menu_wrapper .contato .tel .text {
    font-size: 10px;
    color: #f50;
    text-transform: uppercase;
  }
}
/*# sourceMappingURL=responsive.css.map */
