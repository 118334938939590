@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.App {
  text-align: center;
  min-height: 100vh;
  background: #fff;
  font-family: 'Barlow Semi Condensed', sans-serif;
}
.title_defualt{
  font-size: 3rem;
  width: 100%;
  text-align: left;
  margin-top: 50px;
}

a{
  text-decoration: none;
}